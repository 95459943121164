import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from '@styled-icons/boxicons-regular/Map';
import { Facebook } from '@styled-icons/boxicons-logos/Facebook';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { Clock } from '@styled-icons/fa-regular/Clock';
import { PhoneCall } from '@styled-icons/boxicons-regular/PhoneCall';
import { ExpandMore } from '@styled-icons/material/ExpandMore';
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const HeaderWrapper = styled.header`
  text-align: center;
  font-size: 0.875rem;
  margin-bottom: 40px;
`;

const Container = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;

const Social = styled.div`
  margin-top: 10px;
  padding: 9px 0;

  a {
    padding: 0 5px;
  }

  @media (max-width: 425px) {
    margin-top: 3px;
  }
`;

const Phones = styled.p`
  font-size: 1rem;

  @media (max-width: 425px) {
    margin-bottom: 5px;
  }
`;

const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    max-width: 250px;
    margin: 30px auto;
  }

  @media (max-width: 425px) {
    .gatsby-image-wrapper {
      margin: 30px auto;
    }
  }
`;

const Hours = styled.div`
  margin: 16px auto;
  max-width: 300px;

  .MuiPaper-root {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #ffffff;
  }
  .MuiButtonBase-root {
    color: #ffffff;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
  .MuiCollapse-container {
    text-align: left;
  }
  .MuiAccordionDetails-root {
    ul {
      list-style: none;
      margin: auto 10px;
      width: 100%;
    }
    li {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const Address = styled.p``;

const IconPhoneCall = styled(PhoneCall)`
  margin: 0 2px 3px 2px;
`;

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Container>
          <Social>
            <a
              href="https://www.facebook.com/pizzariaimperioiracemapolis"
              aria-label="Visitar página no Facebook"
            >
              <Facebook size={23} />
            </a>
            <a
              href="https://www.instagram.com/imperio_iracemapolis/"
              aria-label="Página do Instagram"
            >
              <Instagram size={23} />
            </a>
            <a
              href="https://goo.gl/maps/XADyfDjbbYWRJQFR9"
              aria-label="Visitar local no Google Maps"
            >
              <Map size={23} />
            </a>
          </Social>

          <Phones>
            <IconPhoneCall size={23} />
            3456.5230 / 99647.1777
          </Phones>

          <ImageWrapper>
            <Img
              fluid={data.logo.childImageSharp.fluid}
              alt={`Logo ${siteTitle}`}
            />
          </ImageWrapper>

          <Hours>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore size={24} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <Clock size={18} /> &nbsp; Horários de atendimento
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="ul">
                  <li>
                    Segunda <span>18:45 às 23h</span>
                  </li>
                  <li>
                    Terça <span>Fechado</span>
                  </li>
                  <li>
                    Quarta <span>Fechado</span>
                  </li>
                  <li>
                    Quinta <span>18:45 às 23h</span>
                  </li>
                  <li>
                    Sexta <span>18:45 à 23h</span>
                  </li>
                  <li>
                    Sábado <span>18:45 à 23h</span>
                  </li>
                  <li>
                    Domingo <span>18:45 às 23h</span>
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Hours>

          <Address>
            <Map size={17} /> R. Dom Pedro II, 485 - Centro, Iracemápolis/SP
          </Address>
        </Container>
      </HeaderWrapper>
    )}
  />
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
