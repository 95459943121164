import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const NavContainer = styled.div`
  text-align: center;
`;

const StyledButton = styled(Button)`
  background-color: #242424;
  color: #b5ad60;
  border-radius: 12px;
  padding: 6px 12px;
  margin: 5px;

  &:hover,
  &:active {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background-color: #e2dc54;
    color: #242424;
  }
`;

const Nav = () => (
  <NavContainer id="menu">
    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/"
      activeClassName="activeNav"
    >
      Pizzas
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/pizzas-doces/"
      activeClassName="activeNav"
    >
      Pizzas Doces
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/combos/"
      activeClassName="activeNav"
    >
      Combos
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/bordas/"
      activeClassName="activeNav"
    >
      Bordas
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/sobremesas/"
      activeClassName="activeNav"
    >
      Sobremesas
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/bebidas/"
      activeClassName="activeNav"
    >
      Bebidas
    </StyledButton>
  </NavContainer>
);

export default Nav;
