// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bebidas-js": () => import("./../../../src/pages/bebidas.js" /* webpackChunkName: "component---src-pages-bebidas-js" */),
  "component---src-pages-bordas-js": () => import("./../../../src/pages/bordas.js" /* webpackChunkName: "component---src-pages-bordas-js" */),
  "component---src-pages-combos-js": () => import("./../../../src/pages/combos.js" /* webpackChunkName: "component---src-pages-combos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pizzas-doces-js": () => import("./../../../src/pages/pizzas-doces.js" /* webpackChunkName: "component---src-pages-pizzas-doces-js" */),
  "component---src-pages-sobremesas-js": () => import("./../../../src/pages/sobremesas.js" /* webpackChunkName: "component---src-pages-sobremesas-js" */)
}

