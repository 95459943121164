import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';

import Header from './header';
import Nav from './nav';
import BackToTop from './backToTop';
import WhatsappButton from './WhatsappButton';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    background: #050504;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .activeNav {
    background-color: #e2dc54;
    color: #242424;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Footer = styled.footer`
  margin: 30px auto 50px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;

  a {
    text-decoration: underline;
  }

  hr {
    display: block;
    border-top: 3px double rgba(255, 255, 255, 0.3);
  }

  @media (min-width: 768px) {
    margin: 70px auto;
    hr {
      display: none;
    }
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />

        <Container>
          <Header siteTitle={data.site.siteMetadata.title} />

          <Nav />

          {children}

          <BackToTop />

          <Footer>
            <hr />
            <p>
              © {new Date().getFullYear()} - {data.site.siteMetadata.title}
            </p>

            <p>
              Cardápio desenvolvido por{' '}
              <a href="https://mznapplab.com">mznapplab</a>
            </p>
          </Footer>
        </Container>

        <WhatsappButton />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
